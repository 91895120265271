import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/styles3.css';
import logo from '../images/daily.jpg'

class Header extends Component {
  render() {
    const header = {
      height: '290px',
      background: '#fff',
      textAlign: 'center',
  }
   const h1 = {
      fontSize: '50px',
      color: '#ff4800',
      textAlign: 'center'
   }
  
   const h2 = {
      textAlign: 'center'
   }
    return (
      
     <body>
     
    <header className='header-home' style={header}>
      
     <img src={logo} alt='job' />
     
    </header>
    </body>
  
    );
  }
}

export default Header;
