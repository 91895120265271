import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import "../CSS/styles3.css";


export default function Job8() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
     var code={
        float: 'left'
    }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>About Us | Dailyjobsglobal</title>
    <meta name="keywords" content="job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
<meta name="description" content="The Nigerian Army is the largest component of the Nigerian Armed Forces, and is responsible for land warfare operations. It is governed by the Nigerian Army Council." />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
      
      <main>

        <section>
        <h1>Nigerian Army DSSC Graduate Recruitment 2024</h1>

<p>The Nigerian Army is the largest component of the Nigerian Armed Forces, and is responsible for land warfare operations. It is governed by the Nigerian Army Council.</p>

<h3>NIGERIAN ARMY RECRUITMENT ADVERTISEMENT FOR DIRECT SHORT SERVICE COMMISSION COURSE 28/2024</h3>

<p>Applications are invited from eligible Nigerians for commission into the Nigerian Army (NA) as Direct Short Service Commission (DSSC) officers. The DSSC is open to both civilians and serving military personnel. Only serving military personnel sponsored to civil institutions by any of the Services of the Nigerian Armed Forces shall be considered.</p>

<h3 style={code}>CORPS AND SERVICES</h3><br /><br />

<p>Applications should be into any of the following Corps and Services of the Nigerian Army:</p>

<h3 style={code}>a. Nigerian Army Engineers</h3><br /><br />
<p>Applicants must possess a minimum of BSc (Eng), B Eng (Second Class Lower) degree, or HND (Lower Credit) in any of the following areas: Land Surveyor, Structural Engineering, Architecture, and Building Engineering/ Technology, Estate Management, Civil Engineering, GIS/Survey. Such academic fields must be registered with the Nigerian Society of Engineers and Council for the Regulation of Engineering in Nigeria (COREN) or regulating professional bodies.</p>

<h3 style={code}>b. Nigerian Army Signals</h3><br /><br />
<p>Applicants must possess a minimum of BSc, B Tech, B Engr (Second Class Lower) degree or HND (Lower Credit) in any of the following areas: Electrical/Electronic Engineering, Computer Science, Computer Engineering, Cyber Security, Software Developer, Software Engineering, Telecommunications Engineering, Control Security Engineering, Network Designer and Engineering, UAV Pilot, Geographic Information Systems Engineering, Microwave Engineering, Database Management Engineering and Administrator, Project Manager Engineering and System Control Engineering, Satellite Communication Engineering.</p>

<h3>Nigerian Army Intelligence Corps</h3>
<p>Applicants must possess a minimum of BSc, B Eng, B Tech, BA (Second Class Lower) degree or HND (Lower Credit) in any of the following areas: History, Linguistics, Computational Linguistics, Peace and Conflict Analysis, Political Science/International and Public Relations, Computer Science, Information Technology Systems, Digital/Computer Forensics, Cyber/Network Security, Network Engineering, GIS/GEOINT, Data Science, Geospatial Intelligence, Psychology, French, Arabic, Local Language Experts (Hausa, Igbo, Yoruba, Fulfulde and Kanuri), Data Analyst, Social Media Specialist, and Digital Forensics/Forensic Analysis.</p>

<h3>Nigerian Army Corps of Supply and Transport</h3>
<p>Applicants must possess a minimum of BSc, BA (Second Class Lower) degree or HND (Lower Credit) in any of the following areas: Petrochemical Engineering, Dieticians, Food and Nutrition, Automotive Engineering, Logistics Management, Railway Operations Management, Shipping and Supply Chain Management. A good working experience will be an added advantage.</p>

<h3>Nigerian Army Medical Corps</h3>
<p>All applicants for the NA Medical Corps are expected to present proof of registration with their professional regulatory bodies and current practicing license. Other requirements are as follows:</p>
<ul><li>Also check out <a href="https://betathread.com" target="_blank" rel="noreferrer">betathread.com</a></li></ul>

</section>
    </main>
       <Footer />
        </div>
    )
}