import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";


export default function Jobseekers() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }

    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Job Seekers | Dailyjobsglobal</title>
    <meta name="keywords" content="job seekers, job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
    <meta name="description" content="If you are an applicant and job seeker and you are looking for that desired job just sign up and submit your resume with us and browse through our website and submit your resume to the email of that particular job, while you wait for the employer to contact you." />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
        <header style={header}>
       <div className="header-content">
     <div className="centered-content">
   <h1 style={h1}>
            <span>J</span>
            <span>o</span>
            <span>b</span>
            <br />
            <span>S</span>
            <span>e</span>
            <span>e</span>
            <span>k</span>
            <span>e</span>
            <span>r</span>
            <span>s</span>
         
  </h1>
  </div>
  </div>
      </header>
       <main>
        <p>If you are an applicant and job seeker and you are looking for that desired job  
            just sign up and submit your resume with us and browse through our website and submit 
            your resume to the email of that particular job, while you wait for the employer to contact you. </p>


       </main>

       <Footer />
        </div>
    )
}