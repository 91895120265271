import React from 'react';
import "../CSS/styles2.css";
import "../CSS/styles4.css";
import ScrollToTopLink from './ScrollToTop';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer>
    <div className="footer-body">
    <div className='_beta'>
 <h2>DAILY JOBS GLOBAL</h2>
 </div>
<div>
 
 <ul>
  <li><ScrollToTopLink to="/about">About Us</ScrollToTopLink></li>
  <li><ScrollToTopLink to="/contact">Contact Us</ScrollToTopLink></li>
  <li><ScrollToTopLink to="/services">Our Services</ScrollToTopLink></li>
</ul>


       <h2 style={{textAlign: "center"}}>Follow us on</h2>
        <div className="contained">
       <span className="bt-tooltip">
       <a href="https://web.facebook.com/dailyjobsglobal/" title="Daily Jobs Global on facebook" target="_blank" rel="noopener noreferrer"><img src={require('../images/icons8-facebook-53.png')} style={{width: 70, height: 50}} alt='facebook'/></a>
      </span>
    
        </div>
    </div>
    </div><br /><br /><br />

<div class="footer-copyright">
  &copy; 2024 Dailyjobsglobal. All rights reserved 

</div>
        </footer>
        
  );
}

export default Footer;
