import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import "../CSS/styles3.css";


export default function Job13() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
     var code={
        float: 'left'
    }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="keywords" content="job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
    <meta name="description" content="Telco Account Manager" />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
   
      <main>

        <section>
        <h1>Job Title: Telco Account Manager</h1>
<h2>Location:</h2>
<p>Lekki Phase 1, Lagos</p>
<h2>Employment Type:</h2>
<p>Full-time</p>
<h1>Responsibilities</h1>
<ul>
  <li>Experience in managing Telco operator accounts (MTN, Airtel, 9mobile, Glo) is an advantage.</li>
  <li>Understanding telecommunications and/or consumer products and Apps.</li>
  <li>Establish productive, professional relationships with assigned customer accounts and their personnel, at all levels especially at the Management levels.</li>
  <li>Coordinate the involvement of other departments, such as Product Development, Project Management, Service Support, etc., to meet the Business Account performance objectives and customers' expectations.</li>
  <li>Ability to understand customer needs and identify new opportunities in the Business Accounts.</li>
  <li>Candidate should have passion for technology.</li>
  <li>Understand the local telecommunications market space and the global telecommunications market trends and developments.</li>
  <li>Provide input and actively participate in the development and growing of the Business Accounts.</li>
</ul>
<h2>Qualifications</h2>
<ul>
  <li>Bachelor's Degree in Computer Science, Information or Business Sciences, or related fields.</li>
  <li>3 to 6 minimum years’ experience in an Account Management and/or Product Management (customer-facing) role.</li>
</ul>
<h2>Skills / Requirements:</h2>
<ul>
  <li>Advanced skills in the usage of Microsoft Excel, Word and PowerPoint.</li>
  <li>Strong ability to engage with customers by phone, email, and in person.</li>
  <li>Great interpersonal and presentation skills.</li>
  <li>Strong customer focus.</li>
  <li>Excellent communication skills (both verbal and written).</li>
  <li>Excellent time management, analytical and problem-solving skills.</li>
  <li>A self-starter, well organized, and a prolific multi-tasker.</li>
  <li>Innovative and creative.</li>
</ul>
<h1>Salary</h1>
<p>Very Attractive.</p>
<h2>Application Closing Date</h2>
<p>4th March, 2024.</p>
<h2>Method of Application</h2>
<p>Interested and qualified candidates should send their CV to: recruitment.lekki1@gmail.com using "Telco Account Manager" as the subject of the email.</p>
<p>Note: Only qualified candidates will be contacted.</p>



<ul><li>Also check out <a href="https://betathread.com" target="_blank" rel="noreferrer">betathread.com</a></li></ul>
        </section>
    </main>
       <Footer />
        </div>
    )
}