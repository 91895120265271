import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import ScrollToTopLink from "../Components/ScrollToTop";
import "../CSS/styles3.css";


export default function Job1() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
     var code={
        float: 'left'
    }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
 
    <meta name="keywords" content="job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
<meta name="description" content="The service of a Waitress and Kitchen Operator are needed in a hotel" />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
     
      <main>

        <section>
        <h1>The service of a Waitress and Kitchen Operator are needed in a hotel</h1>

        <ul>
<li>Job Description: Waitress</li>
<li>Job Description: Kitchen Operator</li>
<li>Job Location: Agbara Lagos State</li>
</ul>


<h2> Job Requirements</h2>
<ul><li>Experience in the fields is required</li></ul>

<h2>Method of application</h2>
<ul><li>Interested applicants should submit their resume to dailyjobsglobal@gmail.com</li></ul>

<ul><li>Also check out <a href="https://betathread.com" target="_blank" rel="noreferrer">betathread.com</a></li></ul>

        </section>
    </main>
       <Footer />
        </div>
    )
}