import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import "../CSS/styles3.css";


export default function Job6() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
     var code={
        float: 'left'
    }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   
    <meta name="keywords" content="job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
<meta name="description" content="Executive Female Sales Representative at Istanbul Turkish Furniture" />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
   
      <main>

        <section>
        <h1> Executive Female Sales Representative at Istanbul Turkish Furniture </h1> 

<main>
   <section>
       <h3>Introduction</h3>
       <p>At ISTANBUL Turkish Furniture, we bring the exquisite craftsmanship and timeless elegance of Turkish-made furniture to Abuja. As a premier furniture company, we specialize in providing a diverse range of high-quality furniture solutions for homes, hotels, restaurants, and various projects. About Us: At the heart of our company is a commitment to delivering unparalleled style and sophistication. With a passion for design excellence, we offer a curated collection of furniture that seamlessly blends traditional Turkish artistry with contemporary aesthetics. Our pieces are not just furniture; they are a reflection of our dedication to craftsmanship, quality, and innovation. </p>
   </section>

   <section>
       <h3>Executive Female Sales Representative Job Type Full Time, Qualification: MBA/MSc/MAExperience5 years, FieldSales / Marketing / Business Development Salary Range₦150,000 - ₦200,000/month </h3>

</section>

   <section>
       <h3>Job Description</h3>
       <p> Are you a seasoned professional in sales or marketing, with a background in banking or international companies? Join ISTANBUL Turkish Furniture as an Executive Sales Representative and play a pivotal role in expanding our presence in Abuja.</p>
   </section>

   <section>
       <ul><li> Job Location: Abuja</li></ul>
   </section>  

   <section>
       <h3>Main Duties and Responsibilities</h3>
       <ul>
           <li>Identify and cultivate relationships with potential customers, including homeowners, hotels, restaurants, and other showrooms </li>
           <li>Conduct in-person visits to showcase our extensive range of Turkish-made furniture</li>
           <li>Utilize your experience in sales or marketing to effectively close deals and drive sales growth.</li>
           <li>Collaborate with the sales team to develop and implement strategic initiatives for market expansion.</li>
           <li>Represent ISTANBUL Turkish Furniture with professionalism and integrity.</li>
           <li>Understanding our customer and finding them and intrudce our product</li>
           <li>creating marketing strategy to increase sales.</li>
           
       </ul>
   </section>

   <section>
       <h2>Key Competencies</h2>
       <ul>
           <li>Proven experience in sales or marketing, with a preference for those who have worked in banking or international companies.</li>
           <li>Strong interpersonal skills and the ability to build and maintain client relationships.</li>
           <li>Results-driven with a track record of meeting or exceeding sales targets.</li>
           <li>Familiarity with the Abuja market and an understanding of customer needs.</li>
           <li>Excellent negotiation and communication skills.</li>
           <li>Passionate about promoting high-quality Turkish products.</li>
       </ul>
   </section>
   
   <section>
       <h2>What we offer:</h2>
      <ul>

       <li>Competitive salary with commission and bonus structure.li</li>
       <li>Opportunity for career growth within a dynamic and expanding company.</li>
       <li>A supportive and collaborative team environment.</li>
       <li>Training and resources to enhance your sales skills.</li>
       <li>The chance to represent a reputable brand offering premium Turkish furniture</li>
       </ul>

       </section>
       
       
    <section>
       <h2>How to apply</h2>
       <p> If you  interested, and possess the skill as well as experience to prove yourself in this role, please submit your resume and a cover letter to sales@buyfromturkeynj.com until 30 MArch 2024</p>
       <ul><li>Also check out <a href="https://betathread.com" target="_blank" rel="noreferrer">betathread.com</a></li></ul>

   </section>
</main>


        </section>
    </main>
       <Footer />
        </div>
    )
}