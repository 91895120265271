import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import "../CSS/styles3.css";


export default function Job10() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
     var code={
        float: 'left'
    }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="keywords" content="job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
    <meta name="description" content="Sales Agent at Beyond Africa Technology" />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
   
      <main>

        <section>
        <h1>Sales Agent at Beyond Africa Technology</h1>
<h2>Location: Lagos</h2>
<p>Beyond Africa Technology is a Technology Company with core trading on iPhones, Samsung, and Apple Laptops.</p>
<h2>Job Overview:</h2>
<p>As a Sales Agent and Merchant Development Manager, you will play a critical role in market expansion and sales growth. You will be responsible for market research and development, merchant recruitment, ensuring the company achieves its sales targets.</p>
<p><strong>Monthly income: N150,000 (Base Salary+ sales commissions)</strong></p>
<h2>Key Responsibilities:</h2>
<ul>
  <li>Conduct market research and expansion efforts to acquire more merchants to achieve the company's sales targets.</li>
  <li>Establish and maintain strong merchant relationships, provide excellent customer service, and actively promote the company's mobile phone products to achieve sales goals.</li>
  <li>Participate in marketing campaigns to enhance the company's brand awareness and sales volume.</li>
  <li>A Business Developer must have the ability to identify prospective markets and identify new merchants that will increase sales volumes.</li>
</ul>
<h2>Job Requirements:</h2>
<ul>
  <li>Prior experience in merchant development and sales is preferred.</li>
  <li>Bachelor's degree in marketing, market development, or a related field is a plus.</li>
  <li>Excellent market development, communication, and merchant negotiation skills. Candidates with relevant industry experience will be given preference.</li>
  <li>Strong interest and understanding of the mobile phone market or the electronic products sector.</li>
  <li>Team player with a positive work attitude.</li>
  <li>Strong resilience, willingness to work hard, and a desire to earn a high income.</li>
</ul>
<h2>Benefits:</h2>
<ul>
  <li>Competitive salary and incentive plans (sales commissions not included).</li>
  <li>Training and career development opportunities.</li>
  <li>Company benefits and employee activities.</li>
</ul>
<p>If you are interested in joining our team and meet the above requirements, please send your resume to the specified contact. If you have a strong desire to earn a high income and are willing to put in the effort to change your life, we welcome your application!</p>
<h2>Method of Application</h2>
<p>Interested and qualified candidates should forward their CV to:hr@beyondafrica.ng using the position as the subject of the email.</p>

<ul><li>Also check out <a href="https://betathread.com" target="_blank" rel="noreferrer">betathread.com</a></li></ul>

        </section>
    </main>
       <Footer />
        </div>
    )
}