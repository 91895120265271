import React from "react";
import Loginbuttonlight from "./Loginbuttonlight";
import Signupbuttonlight from "./Signupbuttonlight";
import { Link } from 'react-router-dom';
import ScrollToTopLink from "./ScrollToTop";
import '../CSS/styles4.css';


function Navbar() {
    var navButton = {
        width: 'auto',
        padding: '20px',
        background: 'blue',
        color: 'white',
        margin: '1px',
        borderRadius: '20px',
        height: '45px',
        textAlign: 'center',
        cursor: 'pointer'
    };

    const betaThread = {
        fontSize: '23px',
        color: 'white',
        cursor: 'pointer'
    }

    const betaLogo = {
        width: '150px',
        height: '70px',
        cursor: 'pointer',
    }

    const code = {
        float: 'right',
        width: '100%'
    }

  

  
    return (
        <body>
        <nav>
            <ul>
                <li>
                    <ScrollToTopLink to="/">
                        <img src={require('../images/DAILYJOBS LOGO.png')} title="Daily Jobs Home" alt="Daily Job's Logo" style={betaLogo} />
                    </ScrollToTopLink>
                </li>

          

                <li>
                    <ScrollToTopLink to="/login">
                        <Loginbuttonlight />
                    </ScrollToTopLink>
                </li>
              
                <li>
                 
                    <ScrollToTopLink to="/signup">
                        <Signupbuttonlight />
                    </ScrollToTopLink>
                </li>

           
            </ul>
        </nav>
    
        </body>
    )
}

export default Navbar;
