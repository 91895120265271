
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import styles from "../CSS/styles.module.css";
import "../CSS/styles2.css";
import React, { useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import emailjs from '@emailjs/browser';


export default function Contact() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }

     const newCode = {
        background: '#ff4800'
       }

       const navigate = useNavigate();

       const [successMessage, setSuccessMessage] = useState('');
    
    
       const form = useRef();
    
       const sendEmail = (e) => {
         e.preventDefault();
     
         emailjs.sendForm(
           `${process.env.REACT_APP_SERVICE_ID}`,
           `${process.env.REACT_APP_TEMPLATE_ID}`,
           form.current,
           `${process.env.REACT_APP_PUBLIC_KEY}`
           )
           .then((result) => {
            console.log(result.text);
            setSuccessMessage('Email sent successfully!');
            setTimeout(() => {
                navigate("/")
               }, 2000); // Redirect after 2 seconds
           }, (error) => {
            console.error(error.text);
            setSuccessMessage('Error sending email. Please try again.');
           });
       };
     
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Contact Us | Dailyjobsglobal</title>
    <meta name="keywords" content="Contact Dailyjobsglobal, job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
<meta name="description" content="This is daily jobs global contact page. Here you can send us a important messages about job vacancies." />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />

        <header style={header}>
       <div className="header-content">
     <div className="centered-content">
   <h1 style={h1}>
       <span>c</span>
        <span>o</span>
        <span>n</span>
        <span>t</span>
        <span>a</span>
        <span>c</span>
        <span>t</span>
       <br />
        <span>U</span>
        <span>s</span>
  
  </h1>
  </div>
  </div>
      </header>
       <main>

       <h1 className={styles.betaheading}>Write your message to here</h1><br />
       <form ref={form} onSubmit={sendEmail}>

<div className="input-cnt">
        <input type="text" placeholder="Name in full..." name="user_name" required />
        <i class="material-icons">person</i>
    </div>

<div className="input-cnt">
        <input type="email" placeholder="Enter Email" name="user_email" required />
        <i class="material-icons">email</i>
    </div>
  
  
    <div className="input-cnt">
        <textarea cols="50" rows="10" placeholder="Your message here..." name="message"></textarea>
        <i class="material-icons">message</i>
    </div>
    <div className="input-cnt">
        <input type="submit" value="send" style={newCode} />
    </div>

    {successMessage && <p style={{color: 'white', backgroundColor: '#ff4800', width: '270px', height: '30px'}}>{successMessage}</p>}
      </form>
   

       </main>

       <Footer />
        </div>
    )
}