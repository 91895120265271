import { Link } from "react-router-dom";
import Topnav from "../topnav";
import ScrollToTopLink from "../ScrollToTop";
import { Helmet } from "react-helmet";
import Navbar from "../navbar";



const Null = () => {

    const message = {
        textAlign: 'center',
        padding: '30px',
        borderRadius: '10px',
        color: 'white'
    }

    const code = {
        color: '#ff4800',
        textAlign: "center",
        fontSize: "50px",
       
      }

      const codes = {
        color: '#ff4800',
        textAlign: "center",
        fontSize: "150px",
      
      }

      const code2 = {
        color: '#ff4800',
        textAlign: "center",
        fontSize: "30px",
       
      }
    return (
        <body>
          <Helmet>
          <meta name="description" content="Sorry! The page you have requested for cannot be found." />
          <title>404 - Page not found | Dailyjobsglobal</title>
          </Helmet>
            <Navbar /><br /><br /><br /><br />
          
    <div className="null">
      
        <h1 style={codes}>404</h1>
        <p style={code2}>Sorry! The page you have requested for cannot be found.</p>
        <div style={message}>
        <ScrollToTopLink to="/"><button>Back to the home page</button></ScrollToTopLink>
        
    </div>
    </div>
    </body>
    );
}

export default Null;