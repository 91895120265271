import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import "../CSS/styles3.css";


export default function Job7() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
     var code={
        float: 'left'
    }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="keywords" content="job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
<meta name="description" content="Kinabs Infiniti Nigeria Limited provides first class services in the Supply, Installation and Maintenance of Medical Equipment and Laboratory Fittings. We are actively involved in marketing of quality Scientific/Laboratory Equipment, Disposable Hospital Materials, Surgical Materials, Diagnostic Kits / Reagents and X-Ray Materials." />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
   
      <main>

        <section>
        <h1>Medical Lab Scientist at Kinabs Infiniti Nigeria Limited</h1>

<h3>About Kinabs Infiniti Nigeria Limited</h3>
<p>Kinabs Infiniti Nigeria Limited provides first class services in the Supply, Installation and Maintenance of Medical Equipment and Laboratory Fittings. We are actively involved in marketing of quality Scientific/Laboratory Equipment, Disposable Hospital Materials, Surgical Materials, Diagnostic Kits / Reagents and X-Ray Materials.</p>

<h3 style={code}>Medical Lab Scientist</h3><br /><br />
<p>Job Type: Full Time</p>
<p>Location: Abuja</p>
<p>Job Field: Medical / Healthcare</p>

<p>A Medical Laboratory Scientist is urgently needed in our Sister Company for employment.</p>

<p>Minimum 3 years hands-on experience.</p>

<ul>
<li>Receive, test and study urine, blood or other samples for analysis</li>
<li>Performing tests in chemical pathology, hematology, parasitology and medical microbiology</li>
<li>Manage, operate and maintain laboratory equipment</li>
<li>Evaluate test results and prepare reports</li>
<li>Maintain laboratory quality assurance and safety standards</li>
<li>Guide and supervise junior staff and technicians</li>
</ul>

<h3>Method of Application</h3>
<p>Interested and qualified candidates should forward their CV to kinlcareer@gmail.com" using the position as subject of email.</p>

<ul><li>Also check out <a href="https://betathread.com" target="_blank" rel="noreferrer">betathread.com</a></li></ul>

        </section>
    </main>
       <Footer />
        </div>
    )
}