import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";


export default function Vacancies() {
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Vacancies | Dailyjobsglobal</title>
    <meta name="keywords" content="our vacancies, vacancies, job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml,  jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me"/>
    <meta name="robots" content="INDEX,FOLLOW" />
<meta name="description" content="This is the vacancy page for daily jobs global." />

        </Helmet>
       
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
       <main>


       </main>

       <Footer />
        </div>
    )
}