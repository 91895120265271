import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import "../CSS/styles3.css";


export default function Job5() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
     var code={
        float: 'left'
    }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  
    <meta name="keywords" content="About Dailyjobsglobal, job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
<meta name="description" content="Take your next career step. Search for jobs, read career advice and sign up for alerts on the latest vacancies from top companies." />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
        <header style={header}>
       <div className="header-content">
     <div className="centered-content">
   <h1 style={h1}>
   <span>A</span>
    <span>b</span>
    <span>o</span>
    <span>u</span>
    <span>t</span><br />
    <span>U</span>
    <span>s</span>
  
  
  </h1>
  </div>
  </div>
      </header>
      <main>

        <section>
        <p>Getting the right candidate can be very challenging, as well as that perfect dream job, and that is why
    we at Dailyjobsgloba, take it upon our selves to do the entire work of matching successful  applicants
    to the right company.</p>
    <p>At Dailyjobsglobal, we bridge the gap between employers of labour and employees.  </p>

         
            <p>DailyJobsGlobal is dedicated to connecting job seekers with opportunities worldwide. We understand the importance of finding meaningful employment, and our mission is to make the job search process easier and more efficient for everyone.</p>
        </section>

        <section>
            <h1 style={h2}>Our Vision</h1>
            <p>Our vision is to become the go-to platform for job seekers and employers across the globe. We strive to provide a comprehensive and user-friendly experience that empowers individuals to find their dream jobs and helps companies recruit top talent effortlessly.</p>
        </section>

        <section>
            <h1 style={h2}>Why Choose DailyJobsGlobal?</h1>
            <p>At DailyJobsGlobal, we prioritize user satisfaction and convenience. Here are some reasons to choose us:</p>
            <ul>
                <li>Wide Range of Opportunities: We offer a diverse selection of job listings from various industries and locations.</li>
                <li>User-Friendly Interface: Our website is designed to be intuitive and easy to navigate, ensuring a seamless experience for all users.</li>
                <li>Global Reach: Whether you're looking for local opportunities or willing to explore international options, we've got you covered.</li>
                <li>Responsive Support: Our dedicated support team is here to assist you with any questions or concerns you may have along the way.</li>
            </ul>
        </section>

        <section>
            <h2 style={h2}>Contact Us</h2>
            <p>If you have any inquiries or feedback, feel free to reach out to us:</p>
            <address>
                Email: dailyjobsglobal@gmail.com<br />
                Phone: +2347032531275<br />
             
            </address>
            <ul><li>Also check out <a href="https://betathread.com" target="_blank" rel="noreferrer">betathread.com</a></li></ul>

        </section>
    </main>
       <Footer />
        </div>
    )
}