import React from "react";


export default function Signupbuttonlight() {
    var navButton = {
        backgroundColor: 'white',
        width: 'auto',
        padding: '15px',
        color: '#ff4800',
        margin: '20.5px',
        borderRadius: '20px',
        height: '40px',
        textAlign: 'center',
        cursor: 'pointer'
     };
    return(
        <button className="button" style={navButton}>Signup</button>
    )
}