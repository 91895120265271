// React Component

import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../CSS/styles3.css';

export default function Topnav() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const code = {
    backgroundColor: "#ff4800",
    color: "#fff",
    textDecoration: "none"
  };
  const code2 = {
    backgroundColor: "darkblue",
    color: "white",
    textDecoration: "none",
    float: "left"
  };

  return (
    <div>
      <div className="burger-icon2" onClick={toggle}>
      &nbsp;&nbsp;
      Menu &#9776;
     
        </div>
        
      <div className={`topnav ${isOpen ? 'responsive' : 'closed'}`} id="mytopnav">
      
     
        <Link>&nbsp;<span className="close2" onClick={toggle}>&times;</span></Link>
        <Link to="/about" style={code} className="all">ABOUT US</Link> 
        <Link to="/contact" style={code} className="all">CONTACT US</Link>
        <Link to="/services" style={code} className="all">OUR SERVICES</Link>
        <Link to="/jobseekers" style={code} className="all">JOB SEEKERS</Link>
        <Link to="/employers" style={code} className="all">EMPLOYERS</Link>
        
      
        </div>
       
    </div>
  );
}
