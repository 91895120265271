import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Employers from './Pages/Employers';
import Jobseekers from './Pages/Job Seekers';
import Vacancies from './Pages/Vacancies';
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import Signup from './Pages/Signup';
import Login from './Pages/Login';
import Null from './Components/404/Null';
import Job1 from './Jobs/job1';
import Job2 from './Jobs/job2';
import Job3 from './Jobs/job3';
import Job4 from './Jobs/job4';
import Job5 from './Jobs/job5';
import Job6 from './Jobs/job6';
import Job7 from './Jobs/job7';
import Job8 from './Jobs/job8';
import Job9 from './Jobs/job9';
import Job10 from './Jobs/job10';
import Job11 from './Jobs/job11';
import Job12 from './Jobs/job12';
import Job13 from './Jobs/job13';

function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path='/' index element={<Home />} />
    <Route path='/about' element={<About />} />
    <Route path='/employers' element={<Employers />} />
    <Route path='/jobseekers' element={<Jobseekers />} />
    <Route path='/vacancies' element={<Vacancies />} />
    <Route path='/contact' element={<Contact />} />
    <Route path='/services' element={<Services />} />
    <Route path='/signup' element={<Signup />} />
    <Route path='/login' element={<Login />} />
    <Route path='/job1' element={<Job1 />} />
    <Route path='/job2' element={<Job2 />} />
    <Route path='/job3' element={<Job3 />} />
    <Route path='/job4' element={<Job4 />} />
    <Route path='/job5' element={<Job5 />} />
    <Route path='/job6' element={<Job6 />} />
    <Route path='/job7' element={<Job7 />} />
    <Route path='/job8' element={<Job8 />} />
    <Route path='/job9' element={<Job9 />} />
    <Route path='/job10' element={<Job10 />} />
    <Route path='/job11' element={<Job11 />} />
    <Route path='/job12' element={<Job12 />} />
    <Route path='/job13' element={<Job13 />} />


    <Route path='*' element={<Null/>} />
   </Routes>
   </BrowserRouter>
  );
}

export default App;
