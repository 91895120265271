import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";


export default function Employers() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Employers | Dailyjobsglobal</title>
    <meta name="keywords" content="employers, job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml,  jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me"/>
    <meta name="robots" content="INDEX,FOLLOW" />
    <meta name="description" content="As an employer, you are welcomed to Daily Jobs Global. Here you can sign up, open an account and post your job vacancies." />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
        <header style={header}>
       <div className="header-content">
     <div className="centered-content">
   <h1 style={h1}>
   <span>E</span>
        <span>m</span>
        <span>p</span>
        <span>l</span>
        <span>o</span>
        <span>y</span>
        <span>e</span>
     
        <span>r</span>
        <span>s</span>
  
  </h1>
  </div>
  </div>
      </header>
      <main>
       <p> As an employer, you are welcomed to Daily Jobs Global. Here you can sign up, open an 
       account and post your job vacancies.</p>


      </main>
   

       <Footer />
        </div>
    )
}