import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import "../CSS/styles3.css";


export default function Job9() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
     var code={
        float: 'left'
    }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="keywords" content="job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
    <meta name="description" content="Applicants could belong to fields of specialization such as General Surgery, Orthopaedic Surgery, Anaesthesia, Neuro-Surgery, Cardiothoracic, ENT Surgery, Physicians (Cardiologists, Endocrinologist, Nephrologists and Neurologists), Ophthalmologists as well as Obstetrics and Gynaecologists, Pediatricians and Family Medicine Physicians." />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
   
      <main>

        <section>
        <h1>Medical Consultants of Various Disciplines</h1>
<p>Applicants could belong to fields of specialization such as General Surgery, Orthopaedic Surgery, Anaesthesia, Neuro-Surgery, Cardiothoracic, ENT Surgery, Physicians (Cardiologists, Endocrinologist, Nephrologists and Neurologists), Ophthalmologists as well as Obstetrics and Gynaecologists, Pediatricians and Family Medicine Physicians. Applicants must also satisfy the following criteria:</p>
<ul>
<li>Fellow of the National Postgraduate Medical College of Nigeria or equivalent Postgraduate Medical College.</li> 
<li>Full registration of the Medical and Dental Council of Nigeria (MDCN) for the undergraduate degree and additional qualification registration as appropriate.</li> 
<li>Current MDCN license to practice as a specialist.</li> 

</ul>
<ul><li>Also check out <a href="https://betathread.com" target="_blank" rel="noreferrer">betathread.com</a></li></ul>

        </section>
    </main>
       <Footer />
        </div>
    )
}