import React from 'react';
import { Helmet } from 'react-helmet';

function Metatag() {
    return (
    <>
    
    </>
    )
}

export default Metatag