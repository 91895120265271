import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import "../CSS/styles3.css";


export default function Job4() {

    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
     var code={
        float: 'left'
    }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    
    <meta name="keywords" content="job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
<meta name="description" content="Architect at Urban Dice Designs" />

        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
      
      <main>

      <h1> Architect at Urban Dice Designs</h1> 

    <section>
        <h2>Introduction</h2>
        <p>In September 2010, the founder Omowunmi Imoukhuede took the words "Urban" which means "city" and "Dice" which stands for Design, Interior, Concept, Excellence and she put them together. Today Urban Dice is both the name and the idea behind the company. Ever since 2010, Urban Dice has continually strived to redefine the way the African contemporary home is viewed.</p>
    </section>

    <section>
        <h2>Job Purpose</h2>
        <p>Plan, Develop, Implement, Building Designs, create project proposals, estimate costs, determine timelines and oversee construction processes.</p>
    </section>

    <section>
        <h2>Main Duties and Responsibilities</h2>
        <ul>
            <li>Lead and develop projects from early concept through design development</li>
            <li>Prepare drawings, specifications, and construction documents</li>
            <li>Strategize for handling projects</li>
            <li>Develop and prepare reports and records relating to particular assignments or projects</li>
            <li>Follow up on workers and ensure 3D drawings are being achieved from the start of the project</li>
            <li>Give daily feedback on projects, site observations, and site project material needs that may slow down workflow and process</li>
            <li>Sketch Preliminary plans, including electrical and partition layouts</li>
            <li>Oversee project site and coordinate with general building contractors to implement the plans and specifications for the project</li>
            <li>Visit the site till the project is complete, to ensure that the desired result is achieved</li>
        </ul>
    </section>

    <section>
        <h2>Key Competencies</h2>
        <ul>
            <li>Plan, organize and prioritize work</li>
            <li>Good Interpersonal communication and people management</li>
            <li>Remain calm and positive in challenging circumstances</li>
            <li>An eye for Detail and Problem Solving</li>
            <li>Teamwork & Negotiation skills</li>
        </ul>
    </section>

    <section>
        <h2>Qualification & Experience</h2>
        <p>BA/BSc/HND with 2 - 5 years of experience in Building and Construction, Engineering / Technical field.</p>
        <ul><li>Also check out <a href="https://betathread.com" target="_blank" rel="noreferrer">betathread.com</a></li></ul>

    </section>

    </main>
       <Footer />
        </div>
    )
}