import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";


export default function Home() {
    return (
       
        <div>
        <Helmet>
       <Metatag />
        </Helmet>
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
        <Header />
       
        <Body />
      <Footer />
        <div id="root"></div>
        </div>
    )
}