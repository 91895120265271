import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Body from "../Components/Home-body";
import { Helmet } from 'react-helmet';
import Metatag from "../Components/MetaTags";
import Topnav from "../Components/topnav";
import Navbar from "../Components/navbar";
import "../CSS/styles3.css";


export default function Services() {
    const header = {
        padding: '140px',
        textAlign: 'center',
        background: '#fff',
        textAlign: 'center',
    }
     const h1 = {
        fontSize: '50px',
        color: '#ff4800',
        textAlign: 'center'
     }
    
     const h2 = {
        textAlign: 'center'
     }
    return (
       
        <div>
        <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Services | Dailyjobsglobal</title>
    <meta name="keywords" content="our services, job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml,jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me  "/>
    <meta name="robots" content="INDEX,FOLLOW" />
    <meta name="description" content="Explore thousands of job listings from various industries and locations worldwide. We offer a comprehensive range of job opportunities to match your skills and preferences." />

        </Helmet>
       
        <br />  <br />  <br />  <br />  <br />
        <Navbar />
        <Topnav />
        <header style={header}>
       <div className="header-content">
     <div className="centered-content">
   <h1 style={h1}>
            <span>O</span>
            <span>u</span>
            <span>r</span>
            <br />
            <span>S</span>
            <span>e</span>
            <span>r</span>
            <span>v</span>
            <span>i</span>
            <span>c</span>
            <span>e</span>
            <span>s</span>
  
  
  </h1>
  </div>
  </div>
      </header>
        <main>
        <section>
            <h2 style={h2}>Job Listings</h2>
            <p>Explore thousands of job listings from various industries and locations worldwide. We offer a comprehensive range of job opportunities to match your skills and preferences.</p>
        </section>

        <section>
            <h2 style={h2}>Resume Posting</h2>
            <p>Upload your resume to our platform and increase your visibility to employers. Let recruiters find you by showcasing your skills, experience, and qualifications.</p>
        </section>

        <section>
            <h2 style={h2}>Job Alerts</h2>
            <p>Sign up for job alerts to receive notifications about new job openings that match your criteria. Stay informed about the latest opportunities without having to constantly search for them.</p>
        </section>

        <section>
            <h2 style={h2}>Company Profiles</h2>
            <p>Learn more about companies hiring on our platform through detailed company profiles. Discover information about their mission, culture, benefits, and current job openings.</p>
        </section>

        <section>
            <h2 style={h2}>Career Resources</h2>
            <p>Access valuable career resources, including resume writing tips, interview preparation guides, career development advice, and more. Equip yourself with the tools you need to succeed in your job search.</p>
        </section>
    </main>

       <Footer />
        </div>
    )
}