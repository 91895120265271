import React, { useState } from 'react'
import "../CSS/forms.css";
import { account } from '../Components/Appwrite';
import { useNavigate } from 'react-router-dom';
import styles from "../CSS/styles.module.css";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Signup = () => {

  const [userData, setUserData] = useState({ name: "", email: "", password: "" });
  const navigate = useNavigate();

  const Signupfunc = async () => {
    // APPWRITE CONFIGURATION
    const signUpProcess = account.create(
      userData.name,
      userData.email,
      userData.password,
      userData.name
    )
    signUpProcess.then(function (res) {
      console.log(res);
      alert("SignUp successful");
      navigate("/login");
    }, function (error) {
      alert(error);
    })
  }


  return (
    <>
      <Helmet>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Login | Dailyjobsglobal</title>
    <meta name="keywords" content="Login Page, job seekers, job search, Dailyjobs, indeed jobs, search engine for jobs, job search engine, job listings, search jobs, career, employment, work, find jobs, rss jobs feed xml, jobs near me, job search, govt job, job, job opportunities, job.com news, remote jobs, google jobs, linkedin jobs, blow jobs, online jobs, usa jobs, indeed jobs near me, warehouse jobs, work from home jobs, highest paying jobs, jobs, steve jobs, receptionist jobs near me "/>
    <meta name="robots" content="INDEX,FOLLOW" />
    <meta name="description" content="Login page for Daily Jobs Global" />

    </Helmet>
   
    <div id="form-main">
      <div id="input-form">
        <h2 id="input-h2">Signup </h2>
        <p id='input-h2'>
              Already have account? <Link to="/login" style={{color: 'white'}}>Log In</Link>
            </p>
        <input type="text" id="input" placeholder='Enter Name' onChange={(e) => setUserData({ ...userData, name: e.target.value })} />
        <input type="email" id="input" placeholder='Enter Email' onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
        <input type="text" id="input" placeholder='Enter Password' onChange={(e) => setUserData({ ...userData, password: e.target.value })} />
        <button onClick={Signupfunc} id="beta-btn">Signup</button>
      </div>
    </div>
    </>
  )
}
export default Signup